import Component from './CustomersAndAwards.universal.js'
import { findComponents, hydrate, reload } from '@kaliber/build/lib/universalComponents'


const components = findComponents({ componentName: 'components_pageOnly_CustomersAndAwards_universal_js' })
const renderResults = components.map(componentInfo => {
  const { props } = componentInfo
  return { props, result: hydrate(<Component {...props} />, componentInfo) }
})

if (module.hot) {
  require('@kaliber/build/lib/hot-module-replacement-client')
  module.hot.accept('./CustomersAndAwards.universal.js', () => {
    renderResults.forEach(({ props, result }) => result.update(<Component {...props} />))
  })
}
